document.addEventListener('DOMContentLoaded', () => {
    const modalButtons = document.querySelectorAll('#homepage-comments [data-modal-toggle]');

    const modal = document.querySelector('.modal-comment');

    modalButtons.forEach(button => {
        button.addEventListener('click', () => {
            const parent = button.closest('.swiper-slide');

            const name = parent.querySelector('.heading-5').innerText;

            const comment = parent.querySelector('.heading-3').innerText;

            const customer = parent.querySelector('.bottom strong').innerText;

            modal.querySelector('.heading-3').innerText = name;

            modal.querySelector('.heading-6').innerText = customer;

            modal.querySelector('.body-explanation').innerText = comment;
        });
    })
});