import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class BackgroundChange {
    static init() {
        gsap.registerPlugin(ScrollTrigger);

        const items = gsap.utils.toArray('[data-background-changer]');

        const onEnter = (item) => {
            document.body.classList.add(item.dataset.backgroundChanger);
        }

        const onLeave = (item) => {
            document.body.classList.remove(item.dataset.backgroundChanger);
        }

        items.forEach((item, i) => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: 'top center',
                    end: 'bottom center',
                    onEnter: () => onEnter(item),
                    onEnterBack: () => onEnter(item),
                    onLeave: () => onLeave(item),
                    onLeaveBack: () => onLeave(item),
                }
            });
        });
    }
}

setTimeout(() => {
    BackgroundChange.init();
}, 1);