// TODO: dynamic scale will be tested

const options = {
    container: '.carousel',
    items: '.scaling-carousel-item',
    itemsPerView: 6,
    duration: 4000,
    delay: 0,
    leavingDuration: 400,
    leavingClass: 'leave',
    activeClass: 'active',
    responsive: {
        0: {
            itemsPerView: 3
        },

        1024: {
            itemsPerView: 4
        },

        1280: {
            itemsPerView: 6
        },
    },
};

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export default class ScalingCarousel {
    container = null;
    items = [];
    currentBreakpoint = null;
    options = {};
    interval = null;

    constructor() {
        this.setOptionsByWindowWidth();

        this.breakpointListener();
    }

    static make() {
        return new ScalingCarousel();
    }

    breakpointListener() {
        window.addEventListener('resize', (e) => {
            this.setOptionsByWindowWidth(window.innerWidth);
        });
    }

    setOptionsByWindowWidth(width = null) {
        if (!width) {
            width = window.innerWidth;
        }

        const breakpoint = Object.keys(options.responsive)
            .map(bp => parseInt(bp))
            .sort((a, b) => b - a)
            .find(bp => bp < width);


        if (this.currentBreakpoint === breakpoint) return;

        this.replaceOptions(options.responsive[breakpoint]);

        this.currentBreakpoint = breakpoint;

        this.prepareElements();
    }

    prepareElements() {
        this.cursor = 0;

        this.#elementCreator(this.options.container, 'container');

        this.#elementCreator(this.options.items, 'items', true);

        this.start();
    }

    replaceOptions(newOptions = {}) {
        Object.keys(options).forEach(key => {
            this.options[key] = newOptions[key] ?? options[key];
        });
    }

    start() {
        if (!this.#available('container') || this.items.length < 0) {
            return;
        }

        if (this.interval) {
            clearInterval(this.interval);
        }

        this.items.forEach(e => e.remove());

        this.next();

        setTimeout(() => {
            this.interval = setInterval(() => {
                this.next();
            }, this.options.duration);
        }, this.options.delay);
    }

    next() {
        const items = shuffle(this.currentItems());

        let randomDelayList = [];

        for (let i = 0; i < this.options.itemsPerView; i++) {
            randomDelayList.push(i * 300);
        }

        randomDelayList = shuffle(randomDelayList);

        const children = [...this.container.children];

        items.forEach((e, index) => {
            setTimeout(() => {
                if (children[index]) {
                    children[index].classList.remove(this.options.activeClass);
                    setTimeout(() => children[index].classList.add(this.options.leavingClass), 100)

                    setTimeout(() => {
                        children[index].classList.remove(this.options.leavingClass);
                        e.classList.add(this.options.activeClass)
                        this.container.replaceChild(e, children[index]);
                    }, this.options.leavingDuration);

                    return
                }

                e.classList.add(this.options.activeClass);

                this.container.appendChild(e);
            }, randomDelayList[index]);
        });
    }

    currentItems() {
        const items = [];
        const lastIndexOfItems = this.items.length - 1;

        for (let i = 0; i < this.options.itemsPerView; i++) {
            const item = this.items[this.cursor];
            items.push(item);

            if (lastIndexOfItems === this.cursor) {
                this.cursor = 0;

                continue;
            }

            this.cursor++;
        }

        return items;
    }

    #elementCreator(el, property, all = false, parent = document) {
        switch (true) {
            case el instanceof NodeList:
            case el instanceof HTMLElement:
                this[property] = el instanceof NodeList ? [...el] : el;
                break;
            case typeof el === 'string' && el !== '':
                this[property] = all ? [...parent.querySelectorAll(el)] : parent.querySelector(el);
                break;
            default:
                this[property] = all ? [] : null;
                break;
        }
    }

    #available(properties) {
        if (typeof properties === 'string') {
            properties = [properties];
        }

        return properties.filter(property => !this[property]).length === 0;
    }
}

ScalingCarousel.make();